import Color from 'color';

export default (primaryColor = '#13802D') => {
  try {
    primaryColor = Color(primaryColor).hex();
  } catch (error) {
    console.warn('Invalid primaryColor:', primaryColor, error);
    primaryColor = '#000000';
  }

  const colorPalette = computed(() => ({
    'builder-gray-white': '#ffffff',
    'builder-gray-100': '#F2F2F7',
    'builder-gray-200': '#E5E5EA',
    'builder-gray-300': '#D1D1D6',
    'builder-gray-400': '#C7C7CC',
    'builder-gray-500': '#8E8E93',
    'builder-gray-600': '#636366',
    'builder-gray-700': '#48484A',
    'builder-gray-800': '#3A3A3C',
    'builder-gray-900': '#1C1C1E',
    'builder-gray-black': '#000000',

    'builder-primary-100': Color(primaryColor).mix(Color('#fff'), 0.88).hex(),
    'builder-primary-200': Color(primaryColor).mix(Color('#fff'), 0.66).hex(),
    'builder-primary-300': Color(primaryColor).mix(Color('#fff'), 0.44).hex(),
    'builder-primary-400': Color(primaryColor).mix(Color('#fff'), 0.22).hex(),
    'builder-primary-500': Color(primaryColor).hex(),
    'builder-primary-500/15': Color(primaryColor).fade(0.85).string(),
    'builder-primary-600': Color(primaryColor).mix(Color('#000'), 0.22).hex(),
    'builder-primary-700': Color(primaryColor).mix(Color('#000'), 0.44).hex(),
    'builder-primary-800': Color(primaryColor).mix(Color('#000'), 0.66).hex(),
    'builder-primary-900': Color(primaryColor).mix(Color('#000'), 0.88).hex(),
  }));

  const tokenColors = computed(() => ({
    'fg-default': {
      light: colorPalette.value['builder-gray-black'],
      lightGray: colorPalette.value['builder-gray-black'],
      dark: colorPalette.value['builder-gray-white'],
      darkGray: colorPalette.value['builder-gray-white'],
      accent: colorPalette.value['builder-gray-white'],
      lightAccent: colorPalette.value['builder-primary-900'],
    },
    'fg-muted': {
      light: colorPalette.value['builder-gray-700'],
      lightGray: colorPalette.value['builder-gray-800'],
      dark: colorPalette.value['builder-gray-300'],
      darkGray: colorPalette.value['builder-gray-200'],
      accent: colorPalette.value['builder-primary-100'],
      lightAccent: colorPalette.value['builder-primary-800'],
    },
    'fg-subtle': {
      light: colorPalette.value['builder-gray-600'],
      lightGray: colorPalette.value['builder-gray-700'],
      dark: colorPalette.value['builder-gray-400'],
      darkGray: colorPalette.value['builder-gray-300'],
      accent: colorPalette.value['builder-primary-200'],
      lightAccent: colorPalette.value['builder-gray-700'],
    },
    'fg-onemphasis': {
      light: colorPalette.value['builder-gray-white'],
      lightGray: colorPalette.value['builder-gray-white'],
      dark: colorPalette.value['builder-gray-white'],
      darkGray: colorPalette.value['builder-gray-white'],
      accent: colorPalette.value['builder-primary-500'],
      lightAccent: colorPalette.value['builder-gray-white'],
    },
    'fg-accent': {
      light: colorPalette.value['builder-primary-500'],
      lightGray: colorPalette.value['builder-primary-600'],
      dark: colorPalette.value['builder-primary-400'],
      darkGray: colorPalette.value['builder-primary-400'],
      accent: colorPalette.value['builder-gray-white'],
      lightAccent: colorPalette.value['builder-primary-600'],
    },
    'bg-default': {
      light: colorPalette.value['builder-gray-white'],
      lightGray: colorPalette.value['builder-gray-100'],
      dark: colorPalette.value['builder-gray-900'],
      darkGray: colorPalette.value['builder-gray-800'],
      accent: colorPalette.value['builder-primary-500'],
      lightAccent: colorPalette.value['builder-primary-100'],
    },
    'bg-subtle': {
      light: colorPalette.value['builder-gray-100'],
      lightGray: colorPalette.value['builder-gray-200'],
      dark: colorPalette.value['builder-gray-800'],
      darkGray: colorPalette.value['builder-gray-700'],
      accent: colorPalette.value['builder-primary-400'],
      lightAccent: colorPalette.value['builder-primary-200'],
    },
    'bg-emphasis': {
      light: colorPalette.value['builder-primary-500'],
      lightGray: colorPalette.value['builder-primary-500'],
      dark: colorPalette.value['builder-primary-500'],
      darkGray: colorPalette.value['builder-primary-500'],
      accent: colorPalette.value['builder-gray-white'],
      lightAccent: colorPalette.value['builder-primary-500'],
    },
    'bg-emphasis-muted': {
      light: colorPalette.value['builder-primary-100'],
      lightGray: colorPalette.value['builder-primary-200'],
      dark: colorPalette.value['builder-primary-800'],
      darkGray: colorPalette.value['builder-primary-700'],
      accent: colorPalette.value['builder-primary-400'],
      lightAccent: colorPalette.value['builder-primary-200'],
    },
    'border-default': {
      light: colorPalette.value['builder-gray-300'],
      lightGray: colorPalette.value['builder-gray-400'],
      dark: colorPalette.value['builder-gray-700'],
      darkGray: colorPalette.value['builder-gray-600'],
      accent: colorPalette.value['builder-primary-300'],
      lightAccent: colorPalette.value['builder-primary-200'],
    },
    'border-muted': {
      light: colorPalette.value['builder-gray-200'],
      lightGray: colorPalette.value['builder-gray-300'],
      dark: colorPalette.value['builder-gray-800'],
      darkGray: colorPalette.value['builder-gray-700'],
      accent: colorPalette.value['builder-primary-400'],
      lightAccent: colorPalette.value['builder-primary-200'],
    },
    'canvas-email': {
      light: colorPalette.value['builder-gray-100'],
      lightGray: colorPalette.value['builder-gray-100'],
      dark: colorPalette.value['builder-gray-black'],
      darkGray: colorPalette.value['builder-gray-black'],
      accent: colorPalette.value['builder-gray-white'],
      lightAccent: colorPalette.value['builder-gray-white'],
    },
  }));

  return {
    colorPalette,
    tokenColors,
  };
};
